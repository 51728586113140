import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const valkeonColor = '#fe2e55'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        valkeon: valkeonColor
      },
      dark: {
        valkeon: valkeonColor
      },
    }
  }
});
