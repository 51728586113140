<template>
    <v-container class="my-12" id="about">
        <h1 class="valkeon--text display-3 text-center mb-12">
            {{ $t("home.about.title") }}
        </h1>

        <v-row class="text-center" dense no-gutters>
            <v-col cols="12" md="6">
                <v-img
                    :src="require('@/assets/img/about-us.jpg')"
                    height="450px"
                />
            </v-col>

            <v-col cols="12" md="6" class="align-self-center">
                <v-card
                    class="py-12 d-flex flex-column"
                    color="#000"
                    height="450px"
                    tile
                    dark
                >
                    <v-card-text class="my-auto">
                        <v-img
                            :src="require('@/assets/img/logo-w-text.png')"
                            height="100px"
                            contain
                        />

                        <br /><br />

                        {{ $t("home.about.text") }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>