<template>
    <v-container class="mt-12" id="clients">
        <h1 class="valkeon--text display-3 text-center mb-12">
            {{ $t("home.clients.title") }}
        </h1>

        <v-row>
            <v-col
                v-for="(client, index) in clients"
                :key="index"
                cols="12"
                md="6"
                lg="4"
            >
                <v-card color="#000" dark width="100%">
                    <v-card-title class="justify-center">
                        {{ client.name }}
                    </v-card-title>

                    <v-carousel
                        v-if="client.imgs && client.imgs.length"
                        :continuous="true"
                        :cycle="cycle"
                        :interval="10000"
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="300"
                    >
                        <v-carousel-item
                            v-for="(img, imgIndex) in client.imgs"
                            :key="imgIndex"
                        >
                            <v-img :src="img.src" height="300" />
                        </v-carousel-item>
                    </v-carousel>

                    <v-card-text class="text-center" style="height: 60px">
                        {{ client.description[language] }}
                    </v-card-text>

                    <v-card-actions class="justify-center">
                        <v-btn
                            icon
                            v-if="client.link"
                            @click="openNewWindow(client.link)"
                            color="valkeon"
                        >
                            <v-icon>mdi-link-variant</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-divider dark class="mt-12" />
    </v-container>
</template>

<script>
export default {
    data: () => ({
        cycle: true,

        clients: [
            {
                name: "UChallenger",
                link: "https://uchallenger.com",
                description: {
                    pt_BR: "Ganhe dinheiro por jogar CS:GO",
                    es_AR: "Gane dinero por jugar CS:GO",
                    en_US: "CS:GO betting site",
                },
                imgs: [
                    {
                        src: require("@/assets/img/clients/uchallenger/1.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/uchallenger/2.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/uchallenger/3.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/uchallenger/4.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/uchallenger/5.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/uchallenger/6.png"),
                        title: "Home",
                        alt: "Client UChallenger Home Page",
                    },
                ],
            },
            {
                name: "Invokers",
                link: "https://www.invokers.com.br",
                description: {
                    pt_BR: "Contrate um coach e melhore suas habilidades em League of Legends",
                    es_AR: "Contrate un coach y mejore sus habilidades en League of Legends",
                    en_US: "Hire a League of Legends coach and improve your skills",
                },
                imgs: [
                    {
                        src: require("@/assets/img/clients/invokers/1.png"),
                        title: "Home",
                        alt: "Client Invokers Home Page",
                    },
                ],
            },
            {
                name: "Stayfilm",
                link: "https://www.stayfilm.com/",
                description: {
                    pt_BR: "Criação de comerciais para televisão de maneira automatizada",
                    es_AR: "Creación de publicidad para televisión de manera automatizada.",
                    en_US: "Create advertisements automatically",
                },
                imgs: [
                    {
                        src: require("@/assets/img/clients/stayfilm/1.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/stayfilm/2.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/stayfilm/3.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/stayfilm/4.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/stayfilm/5.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                    {
                        src: require("@/assets/img/clients/stayfilm/6.png"),
                        title: "Home",
                        alt: "Client Stayfilm Home Page",
                    },
                ],
            },
        ],
    }),
};
</script>