<template>
    <v-card color="#000" class="mt-12" tile dark>
        <v-container>
            <v-row dense class="py-12">
                <v-col cols="12" md="4" :class="centerOnMobile">
                    <span class="valkeon--text text-h5">
                        {{ $t("home.footer.navigation.title") }}
                    </span>

                    <v-divider dark class="mb-5 mt-2" />

                    <v-btn text @click="scrollTo('home')">
                        {{ $t("home.footer.navigation.home") }}
                    </v-btn>

                    <br />

                    <v-btn text @click="scrollTo('services')">
                        {{ $t("home.footer.navigation.services") }}
                    </v-btn>

                    <br />

                    <v-btn text @click="scrollTo('clients')">
                        {{ $t("home.footer.navigation.clients") }}
                    </v-btn>

                    <br />

                    <v-btn text @click="scrollTo('about')">
                        {{ $t("home.footer.navigation.about") }}
                    </v-btn>

                    <br />

                    <!-- <v-btn text>
                        {{ $t("home.footer.navigation.contact") }}
                    </v-btn> -->
                </v-col>

                <v-col cols="12" md="4" :class="centerOnMobile">
                    <span class="valkeon--text text-h5">
                        {{ $t("home.footer.partners.title") }}
                    </span>

                    <v-divider dark class="mb-5 mt-2" />

                    <v-btn
                        text
                        @click="
                            partner.url ? openNewWindow(partner.url) : () => {}
                        "
                        v-for="(partner, index) in partners"
                        :key="index"
                    >
                        {{ partner.name }}
                    </v-btn>
                </v-col>

                <v-col cols="12" md="4" :class="centerOnMobile">
                    <v-img
                        :src="require('@/assets/img/logo-w-text.png')"
                        height="100px"
                        contain
                    />

                    <div class="text-center mt-5 d-flex justify-center">
                        <div v-for="(social, index) in socials" :key="index">
                            <v-btn
                                icon
                                @click="openNewWindow(social.url)"
                                v-if="social.url"
                            >
                                <v-icon>{{ social.icon }}</v-icon>
                            </v-btn>

                            <v-btn
                                icon
                                @click="
                                    openNewWindow(
                                        getSocialUrlByLang(social.name)
                                    )
                                "
                                v-else-if="social.name"
                            >
                                <v-icon>{{ social.icon }}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12">
                    <v-divider class="my-5" dark />
                </v-col>

                <v-col cols="12" class="text-center valkeon--text">
                    Valkeon &copy; 2024 - All rights reserved
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import SocialUrlMixin from "@/mixins/SocialUrlMixin";

export default {
    mixins: [SocialUrlMixin],

    data: () => ({
        partners: [
            {
                name: "Invokers",
                url: "https://www.invokers.com.br",
            },
        ],
        socials: [
            {
                name: "instagram",
                icon: "mdi-instagram",
                url: "https://www.instagram.com/valkeonweb",
            },
            {
                name: "whatsapp",
                icon: "mdi-whatsapp",
            },
            // {
            //     icon: "mdi-facebook",
            //     url: null,
            // },
            // {
            //     icon: "mdi-linkedin",
            //     url: null,
            // },
        ],
    }),

    computed: {
        centerOnMobile() {
            return {
                "text-center":
                    this.$vuetify.breakpoint.smOnly ||
                    this.$vuetify.breakpoint.smAndDown,
                "text-left": this.$vuetify.breakpoint.mdAndUp,
            };
        },
    },

    methods: {},
};
</script>