<template>
    <div>
        <v-card
            id="home"
            color="#000"
            class="centralize"
            tile
            dark
            style="min-height: 100vh"
        >
            <v-container class="mb-12">
                <v-row>
                    <v-col
                        cols="12"
                        md="6"
                        :class="{
                            'align-self-center': true,
                            'pl-12': !isMobile,
                        }"
                    >
                        <div :class="centerOnMobile">
                            <h1 class="valkeon--text display-2 mb-5">
                                {{ $t("home.main.title1") }}
                            </h1>

                            <h1 class="valkeon--text display-2 mb-5">
                                {{ $t("home.main.title2") }}
                            </h1>

                            <h1 class="valkeon--text display-2 mb-12">
                                {{ $t("home.main.title3") }}
                            </h1>

                            <div class="w100">
                                <vue-typed-js
                                    :key="$i18n.locale"
                                    :showCursor="false"
                                    :strings="[$t('home.main.subtitle')]"
                                >
                                    <span class="typing w100" />
                                </vue-typed-js>
                            </div>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" class="py-12">
                        <v-img
                            ref="mainImg"
                            class="animate__animated animate__fadeInRight"
                            :src="
                                require('@/assets/img/isometric-computer-dashboard.png')
                            "
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-container>
            <v-divider dark class="mt-12" />
        </v-container>
    </div>
</template>

<script>
import { VueTypedJs } from "vue-typed-js";

export default {
    components: {
        VueTypedJs,
    },

    data: () => ({
        startTypingSecondText: false,
    }),

    computed: {
        centerOnMobile() {
            return {
                "text-center":
                    this.$vuetify.breakpoint.smOnly ||
                    this.$vuetify.breakpoint.smAndDown,
                "text-left": this.$vuetify.breakpoint.mdAndUp,
            };
        },
        isMobile() {
            return (
                this.$vuetify.breakpoint.smOnly ||
                this.$vuetify.breakpoint.smAndDown
            );
        },
    },

    created() {
        setTimeout(() => (this.startTypingSecondText = true), 2050);
        setTimeout(
            () => this.$refs.mainImg?.$el?.classList?.add("float-animation"),
            1000
        );
    },
};
</script>
