<template>
    <v-container class="mt-12" id="services">
        <h1 class="valkeon--text display-3 text-center mb-12">
            {{ $t("home.services.title") }}
        </h1>

        <v-row class="text-center">
            <v-col cols="12" md="6" lg="4">
                <v-card color="#000" height="320px" dark tile>
                    <v-card-title class="justify-center">
                        {{ $t("home.services.service1") }}
                    </v-card-title>

                    <v-img
                        :src="require('@/assets/img/offer1.png')"
                        class="mx-auto animate__animated animate__fadeIn"
                        width="200px"
                        contain
                    />
                </v-card>
            </v-col>

            <v-col cols="12" md="6" lg="4">
                <v-card color="#000" height="320px" dark tile>
                    <v-card-title class="justify-center">
                        {{ $t("home.services.service2") }}
                    </v-card-title>

                    <v-img
                        :src="require('@/assets/img/offer2.png')"
                        class="mx-auto animate__animated animate__fadeIn"
                        width="200px"
                        contain
                    />
                </v-card>
            </v-col>

            <v-col cols="12" md="6" lg="4">
                <v-card color="#000" height="320px" dark tile>
                    <v-card-title class="justify-center">
                        {{ $t("home.services.service3") }}
                    </v-card-title>

                    <v-img
                        :src="require('@/assets/img/offer3.png')"
                        class="mx-auto animate__animated animate__fadeIn"
                        width="200px"
                        contain
                    />
                </v-card>
            </v-col>
        </v-row>

        <v-divider dark class="mt-12" />
    </v-container>
</template>

<script>
export default {};
</script>