<template>
    <v-app-bar app color="#000000" height="82" dark>
        <v-toolbar-title>
            <img
                :src="require('@/assets/img/logo.png')"
                class="ma-0 pa-2"
                style="width: 70px"
            />
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn text @click="scrollTo('home')">
                {{ $t("navbar.home") }}
            </v-btn>

            <v-btn text @click="scrollTo('services')">
                {{ $t("navbar.services") }}
            </v-btn>

            <v-btn text @click="scrollTo('clients')">
                {{ $t("navbar.clients") }}
            </v-btn>

            <v-btn text @click="scrollTo('about')">
                {{ $t("navbar.about") }}
            </v-btn>
        </template>

        <v-spacer></v-spacer>

        <div class="d-flex flex-wrap">
            <template v-if="displayLanguageOptions">
                <v-img
                    v-for="lang in languages"
                    :key="lang.value"
                    :src="lang.src"
                    width="32px"
                    class="mr-2 cursor-pointer"
                    @click="changeLanguage(lang)"
                />
            </template>

            <v-img
                v-else
                :src="lang.src"
                width="32px"
                class="cursor-pointer"
                @click="displayLanguageOptions = true"
            />
        </div>

        <v-app-bar-nav-icon @click="showSidebar = true" v-if="showOnMobile" />

        <template v-if="showSidebar">
            <v-navigation-drawer absolute permanent height="100vh" width="100%">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6 text-center">
                            <img
                                :src="require('@/assets/img/logo.png')"
                                class="ma-0 pa-2"
                                style="width: 70px"
                            />
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <div class="d-flex flex-column">
                    <v-btn large text @click="wrapperScrollTo('home')">
                        {{ $t("navbar.home") }}
                    </v-btn>

                    <v-btn large text @click="wrapperScrollTo('services')">
                        {{ $t("navbar.services") }}
                    </v-btn>

                    <v-btn large text @click="wrapperScrollTo('clients')">
                        {{ $t("navbar.clients") }}
                    </v-btn>

                    <v-btn large text @click="wrapperScrollTo('about')">
                        {{ $t("navbar.about") }}
                    </v-btn>
                </div>
            </v-navigation-drawer>
        </template>
    </v-app-bar>
</template>

<script>
import availableLanguages from "@/lang/availableLanguages";

export default {
    data: () => ({
        showSidebar: false,
        displayLanguageOptions: false,
        lang: null,
        languages: [
            {
                src: require("@/assets/img/flags/brazil.png"),
                value: "pt_BR",
            },
            {
                src: require("@/assets/img/flags/argentina.jpg"),
                value: "es_AR",
            },
            {
                src: require("@/assets/img/flags/usa.png"),
                value: "en_US",
            },
        ],
    }),

    computed: {
        showOnMobile() {
            return (
                this.$vuetify.breakpoint.smOnly ||
                this.$vuetify.breakpoint.smAndDown
            );
        },
    },

    created() {
        this.setLanguage();
    },

    methods: {
        setLanguage() {
            let browserLang = navigator.language?.replace("-", "_");

            if (browserLang?.startsWith("es")) browserLang = "es_AR";
            if (browserLang?.startsWith("pt")) browserLang = "pt_BR";
            if (browserLang?.startsWith("en")) browserLang = "en_US";

            if (!Object.keys(availableLanguages).includes(browserLang)) {
                browserLang = process.env.VUE_APP_FALLBACK_LANGUAGE || "pt_BR";
            }

            const defaultLanguage =
                localStorage.getItem("lang") ||
                browserLang ||
                process.env.VUE_APP_DEFAULT_LANGUAGE ||
                process.env.VUE_APP_FALLBACK_LANGUAGE ||
                "pt_BR";

            const lang = this.languages.find(
                ({ value }) => value === defaultLanguage
            );

            this.changeLanguage(lang);
        },

        changeLanguage(lang) {
            localStorage.setItem("lang", lang.value);
            this.$i18n.locale = lang.value;
            this.lang = lang;
            this.displayLanguageOptions = false;
        },

        wrapperScrollTo(sectionId) {
            this.scrollTo(sectionId);
            this.showSidebar = false;
        },
    },
};
</script>

<style>
</style>