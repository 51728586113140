export default {
  computed: {
    language() {
      return this.$i18n.locale;
    }
  },

  methods: {
    redirectTo(name) {
      this.$router.push({ name });
    },

    openNewWindow(url) {
      window.open(url)
    },

    scrollTo(sectionId, offset = -90) {
      const el = document.getElementById(sectionId);
      if (!el) return;

      const elementPosition = el.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

      if (sectionId === "home") {
        history.pushState(null, "", window.location.pathname + window.location.search);

        return;
      }

      location.hash = sectionId;
    },


  },

}