<template>
    <v-app>
        <navbar />

        <v-main class="grey darken-4 white--text">
            <router-view />
        </v-main>

        <v-btn
            color="green"
            class="mb-2 mr-2"
            dark
            fixed
            bottom
            right
            fab
            @click="openNewWindow(getSocialUrlByLang('whatsapp'))"
        >
            <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
    </v-app>
</template>

<script>
import Navbar from "@/components/navbar";
import SocialUrlMixin from "@/mixins/SocialUrlMixin";

export default {
    name: "App",

    mixins: [SocialUrlMixin],

    components: { Navbar },

    data: () => ({
        //
    }),

    methods: {},
};
</script>
