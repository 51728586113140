<template>
    <v-row dense>
        <v-col cols="12" class="mt-0 pt-0">
            <main-section />
        </v-col>

        <v-col cols="12">
            <services-section />
        </v-col>

        <v-col cols="12">
            <clients-section />
        </v-col>

        <v-col cols="12">
            <about-section />
        </v-col>

        <v-col cols="12">
            <footer-section />
        </v-col>
    </v-row>
</template>

<script>
import MainSection from "./main.vue";
import ServicesSection from "./services.vue";
import ClientsSection from "./clients.vue";
import AboutSection from "./about.vue";
import FooterSection from "./footer.vue";

export default {
    name: "Home",

    components: {
        MainSection,
        ServicesSection,
        ClientsSection,
        AboutSection,
        FooterSection,
    },
};
</script>