export default {
  methods: {
    getSocialUrlByLang(name) {
      const urlsByLang = {
        whatsapp: {
          pt_BR: "https://api.whatsapp.com/send?phone=5519984450592&text=Olá,%20quero%20fazer%20um%20orçamento%20para%20uma%20aplicação",
          es_AR: "https://api.whatsapp.com/send?phone=5519984450592&text=Hola!%20Quiero%20consultar%20presupuesto%20para%20una%20aplicacion",
          en_US: "https://api.whatsapp.com/send?phone=5519984450592&text=Hello,%20I%20want%20to%20know%20more%20about%20how%20Valkeon%20works",
        },
      };

      const url = urlsByLang[name]?.[this.$i18n.locale];
      const salesRep = this.$route.query.rep;

      if (name === "whatsapp" && salesRep) {
        return `${url}.%20Sales%20Rep.%3A${salesRep}`;
      }

      return url;
    },
  }
}