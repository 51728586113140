import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import GlobalMixin from './mixins/GlobalMixin'

import VueI18n from 'vue-i18n';
import availableLanguages from '@/lang/availableLanguages'

import '@/assets/css/main.css';


Vue.config.productionTip = false
Vue.mixin(GlobalMixin);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_DEFAULT_LANGUAGE || 'pt_BR',
  fallbackLocale: process.env.VUE_APP_FALLBACK_LANGUAGE || 'pt_BR',
  messages: availableLanguages,
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

